body {
  margin: 0;
  padding: 0;
  background-color: #929383;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.svg-container {
  text-align: center;
}